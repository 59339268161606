//make me a component in the style of the other pages that bascially just has a basic text with privacy policy

import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { DESKTOP_HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../components/Layout/Layout"
import breakpoints from "../styles/breakpoints"
import colors from "../styles/colors"

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }
`
const PrivacyPolicy = ( props) => {  
    const { location } = props
    const path = location.pathname
  

  
    let sharingImage = false
    

       return (
        <Layout bg="#319AD5">
 
        <Seo
        title="Privacy Policy"
        description="Privacy Policy"
        fullTitle={true}
        path={path}
      />
      <PageContainer>
            <h2 style={{color: "white"}}>Privacy Policy</h2>
            <p style={{color: "white"}}>We care about data privacy and security. By using the Site or the Marketplace Offerings, you agree to be bound by our Privacy Policy posted on the Site, which is incorporated into these Terms of Use. Please be advised the Site and the Marketplace Offerings are hosted in the United States. If you access the Site or the Marketplace Offerings from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Site, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States. Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. Therefore, in accordance with the U.S. Children’s Online Privacy Protection Act, if we receive actual knowledge that anyone under the age of 13 has provided personal information to us without the requisite and verifiable parental consent, we will delete that information from the Site as quickly as is reasonably practical.</p>
</PageContainer>
            </Layout>
       )
}

export default PrivacyPolicy